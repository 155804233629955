import "./App.css";

import HomePage from "./pages/HomePage";

function App() {
    return (
        <div>
            <HomePage></HomePage>
        </div>
    );
}

export default App;
