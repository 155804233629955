import "../css/HomePage.css";

import { useState } from "react";

const HomePage = () => {
    const [textColor, setTextColor] = useState("#000000"); // 初始文本颜色为黑色
    // 监听鼠标移动事件
    const handleMouseMove = () => {
        // 生成随机颜色
        const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
        setTextColor(randomColor); // 更新文本颜色
    };
    return (
        <>
            <div onMouseMove={handleMouseMove}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "95vh", // 设置容器高度为视口高度
                    }}
                >
                    <p
                        style={{
                            fontSize: "5vw", // 使用视口宽度的百分比来设置字体大小
                            color: textColor, // 设置文本颜色
                        }}
                    >
                        杨奕芸天下最美
                    </p>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "5vh", // 设置容器高度为视口高度
                    }}
                >
                    <a class="icpRef" href="https://beian.miit.gov.cn/" target="_blank">
                        沪ICP备2022030825号-2
                    </a>
                </div>
            </div>
        </>
    );
};

export default HomePage;
